import { useElement } from './core/element.js';
const name = 's-scroll-view';
const props = {};
const style = `
:host{
  display: block;
  overflow: auto;
}
@media (pointer: fine){
  :host::-webkit-scrollbar{
    width: 6px;
    height: 6px;
    background: transparent;
  }
  :host::-webkit-scrollbar-thumb{
    background: var(--s-color-outline-variant, ${"#bfc8cb"});
    border-radius: 3px;
  }
  @supports not selector(::-webkit-scrollbar){
    :host{
      scrollbar-color: var(--s-color-outline-variant, ${"#bfc8cb"}) transparent;
    }
  }
}
`;
const template = `<slot></slot>`;
export class ScrollView extends useElement({ style, template, props, }) {
}
ScrollView.define(name);
