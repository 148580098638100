import { useElement } from './core/element.js';
import './ripple.js';
const name = 's-card';
const props = {
    type: 'standard',
    clickable: false
};
const style = `
:host{
  display: inline-block;
  vertical-align: middle;
  background: var(--s-color-surface-container-high, ${"#f2f4f5"});
  box-shadow: var(--s-elevation-level1, ${"0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12)"});
  border-radius: 12px;
  position: relative;
  font-size: .875rem;
  box-sizing: border-box;
  max-width: 280px;
  color: var(--s-color-on-surface, ${"#191c1d"});
  overflow: hidden;
  flex-shrink: 0;
}
:host([type=filled]){
  background: var(--s-color-surface-container-low, ${"#e6e8e9"});
  box-shadow: none;
}
:host([type=outlined]){
  background: var(--s-color-surface, ${"#fbfcfe"});
  border: solid 1px var(--s-color-outline-variant, ${"#bfc8cb"});
  box-shadow: none;
}
:host([clickable=true]){
  cursor: pointer;
  transition: box-shadow .1s ease-out;
}
:host([clickable=true]) .ripple{
  display: block;
}
.action{
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
.ripple{
  display: none;
  border-radius: 0;
}
::slotted([slot=image]){
  display: block;
  height: 128px;
  background: var(--s-color-surface-container-low, ${"#e6e8e9"});
}
:host([type=filled]) ::slotted([slot=image]){
  background: var(--s-color-surface-container-high, ${"#f2f4f5"});
}
::slotted([slot=headline]){
  font-size: 1.5rem;
  line-height: 1.6;
  margin: 16px 16px 0 16px;
}
::slotted([slot=subhead]){
  font-size: 1rem;
  line-height: 1.6;
  margin: 4px 16px;
}
::slotted([slot=text]){
  line-height: 1.6;
  margin: 8px 16px;
  color: var(--s-color-on-surface-variant, ${"#3f484b"});
}
::slotted(s-button[slot=action]){
  margin: 16px 0;
}
::slotted(s-button[slot=action]:last-of-type){
  margin-right: 16px;
}
@media (pointer: fine){
  :host([clickable=true][type=filled]:hover),
  :host([clickable=true][type=outlined]:hover){
    box-shadow: var(--s-elevation-level1, ${"0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12)"});
  }
  :host([clickable=true]:hover){
    box-shadow: var(--s-elevation-level2, ${"0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)"});
  }
}
`;
const template = `
<slot name="start"></slot>
<slot name="image"></slot>
<slot name="headline"></slot>
<slot name="subhead"></slot>
<slot name="text"></slot>
<slot></slot>
<div class="action" part="action">
  <slot name="action"></slot>
</div>
<slot name="end"></slot>
<s-ripple class="ripple" attached="true" part="ripple"></s-ripple>
`;
export class Card extends useElement({
    style, template, props, syncProps: true,
    setup(shadowRoot) {
        const action = shadowRoot.querySelector('slot[name=action]');
        action.addEventListener('pointerdown', (e) => e.stopPropagation());
    }
}) {
}
Card.define(name);
