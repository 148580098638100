import { useElement } from './core/element.js';
const name = 's-page';
const props = {
    theme: 'light'
};
const style = `
:host{
  display: flow-root;
  color: var(--s-color-on-background);
  background: var(--s-color-background);
  font-family: Roboto, system-ui;
  height: 100%;
  --s-color-scrim: ${"#000000"};
  --s-color-primary: ${"#00687b"};
  --s-color-on-primary: ${"#ffffff"};
  --s-color-primary-container: ${"#aeecff"};
  --s-color-on-primary-container: ${"#001f26"};
  --s-color-secondary: ${"#4b6269"};
  --s-color-on-secondary: ${"#ffffff"};
  --s-color-secondary-container: ${"#cee7ef"};
  --s-color-on-secondary-container: ${"#061f25"};
  --s-color-tertiary: ${"#575c7e"};
  --s-color-on-tertiary: ${"#ffffff"};
  --s-color-tertiary-container: ${"#dee1ff"};
  --s-color-on-tertiary-container: ${"#141937"};
  --s-color-error: ${"#ba1a1a"};
  --s-color-on-error: ${"#ffffff"};
  --s-color-error-container: ${"#ffdad6"};
  --s-color-on-error-container: ${"#410002"};
  --s-color-background: ${"#fbfcfe"};
  --s-color-on-background: ${"#191c1d"};
  --s-color-outline: ${"#70797c"};
  --s-color-outline-variant: ${"#bfc8cb"};
  --s-color-surface: ${"#fbfcfe"};
  --s-color-on-surface: ${"#191c1d"};
  --s-color-surface-variant: ${"#dbe4e7"};
  --s-color-on-surface-variant: ${"#3f484b"};
  --s-color-inverse-surface: ${"#2e3132"};
  --s-color-inverse-on-surface: ${"#eff1f2"};
  --s-color-inverse-primary: ${"#56d6f5"};
  --s-color-surface-container: ${"#eceeef"};
  --s-color-surface-container-high: ${"#f2f4f5"};
  --s-color-surface-container-highest: ${"#ffffff"};
  --s-color-surface-container-low: ${"#e6e8e9"};
  --s-color-surface-container-lowest: ${"#e1e3e4"};
  --s-color-dark-primary: ${"#56d6f5"};
  --s-color-dark-on-primary: ${"#003641"};
  --s-color-dark-primary-container: ${"#004e5d"};
  --s-color-dark-on-primary-container: ${"#aeecff"};
  --s-color-dark-secondary: ${"#b2cbd3"};
  --s-color-dark-on-secondary: ${"#1d343a"};
  --s-color-dark-secondary-container: ${"#344a51"};
  --s-color-dark-on-secondary-container: ${"#cee7ef"};
  --s-color-dark-tertiary: ${"#bfc4eb"};
  --s-color-dark-on-tertiary: ${"#292e4d"};
  --s-color-dark-tertiary-container: ${"#3f4565"};
  --s-color-dark-on-tertiary-container: ${"#dee1ff"};
  --s-color-dark-error: ${"#ffb4ab"};
  --s-color-dark-on-error: ${"#690005"};
  --s-color-dark-error-container: ${"#93000a"};
  --s-color-dark-on-error-container: ${"#ffb4ab"};
  --s-color-dark-background: ${"#191c1d"};
  --s-color-dark-on-background: ${"#e1e3e4"};
  --s-color-dark-outline: ${"#899295"};
  --s-color-dark-outline-variant: ${"#3f484b"};
  --s-color-dark-surface: ${"#191c1d"};
  --s-color-dark-on-surface: ${"#e1e3e4"};
  --s-color-dark-surface-variant: ${"#3f484b"};
  --s-color-dark-on-surface-variant: ${"#bfc8cb"};
  --s-color-dark-inverse-surface: ${"#e1e3e4"};
  --s-color-dark-inverse-on-surface: ${"#2e3132"};
  --s-color-dark-inverse-primary: ${"#00687b"};
  --s-color-dark-surface-container: ${"#1d2021"};
  --s-color-dark-surface-container-high: ${"#272a2c"};
  --s-color-dark-surface-container-highest: ${"#323536"};
  --s-color-dark-surface-container-low: ${"#191c1d"};
  --s-color-dark-surface-container-lowest: ${"#0b0f10"};
  --s-elevation-level1: ${"0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12)"};
  --s-elevation-level2: ${"0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)"};
  --s-elevation-level3: ${"0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12)"};
  --s-elevation-level4: ${"0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12)"};
  --s-elevation-level5: ${"0 10px 14px -6px rgba(0, 0, 0, .2), 0 22px 35px 3px rgba(0, 0, 0, .14), 0 8px 42px 7px rgba(0, 0, 0, .12)"};
}
:host([dark]){
  --s-color-primary: var(--s-color-dark-primary) !important;
  --s-color-on-primary: var(--s-color-dark-on-primary) !important;
  --s-color-primary-container: var(--s-color-dark-primary-container) !important;
  --s-color-on-primary-container: var(--s-color-dark-on-primary-container) !important;
  --s-color-secondary: var(--s-color-dark-secondary) !important;
  --s-color-on-secondary: var(--s-color-dark-on-secondary) !important;
  --s-color-secondary-container: var(--s-color-dark-secondary-container) !important;
  --s-color-on-secondary-container: var(--s-color-dark-on-secondary-container) !important;
  --s-color-tertiary: var(--s-color-dark-tertiary) !important;
  --s-color-on-tertiary: var(--s-color-dark-on-tertiary) !important;
  --s-color-tertiary-container: var(--s-color-dark-tertiary-container) !important;
  --s-color-on-tertiary-container: var(--s-color-dark-on-tertiary-container) !important;
  --s-color-error: var(--s-color-dark-error) !important;
  --s-color-on-error: var(--s-color-dark-on-error) !important;
  --s-color-error-container: var(--s-color-dark-error-container) !important;
  --s-color-on-error-container: var(--s-color-dark-on-error-container) !important;
  --s-color-background: var(--s-color-dark-background) !important;
  --s-color-on-background: var(--s-color-dark-on-background) !important;
  --s-color-outline: var(--s-color-dark-outline) !important;
  --s-color-outline-variant: var(--s-color-dark-outline-variant) !important;
  --s-color-surface: var(--s-color-dark-surface) !important;
  --s-color-on-surface: var(--s-color-dark-on-surface) !important;
  --s-color-surface-variant: var(--s-color-dark-surface-variant) !important;
  --s-color-on-surface-variant: var(--s-color-dark-on-surface-variant) !important;
  --s-color-inverse-surface: var(--s-color-dark-inverse-surface) !important;
  --s-color-inverse-on-surface: var(--s-color-dark-inverse-on-surface) !important;
  --s-color-inverse-primary: var(--s-color-dark-inverse-primary) !important;
  --s-color-surface-container: var(--s-color-dark-surface-container) !important;
  --s-color-surface-container-high: var(--s-color-dark-surface-container-high) !important;
  --s-color-surface-container-highest: var(--s-color-dark-surface-container-highest) !important;
  --s-color-surface-container-low: var(--s-color-dark-surface-container-low) !important;
  --s-color-surface-container-lowest: var(--s-color-dark-surface-container-lowest) !important;
}
`;
const template = `<slot></slot>`;
export class Page extends useElement({
    style, template, props,
    setup() {
        const darker = matchMedia('(prefers-color-scheme: dark)');
        const isDark = () => {
            if (this.theme === 'auto')
                return darker.matches;
            if (this.theme === 'dark')
                return true;
            return false;
        };
        const toggle = (theme, trigger) => {
            if (this.theme === theme)
                return;
            const isDark = darker.matches;
            const getTheme = (theme) => theme === 'auto' ? (isDark ? 'dark' : 'light') : theme;
            const oldTheme = getTheme(this.theme);
            const newTheme = getTheme(theme);
            if (oldTheme === newTheme || !document.startViewTransition) {
                this.theme = theme;
                return;
            }
            const info = { x: innerWidth / 2, y: innerHeight / 2 };
            if (trigger) {
                const rect = trigger.getBoundingClientRect();
                info.x = rect.x + rect.width / 2;
                info.y = rect.y + rect.height / 2;
            }
            document.styleSheets[0].insertRule(`
      ::view-transition-old(root),
      ::view-transition-new(root) {
        animation: none;
        mix-blend-mode: normal;
      }`, 0);
            const transition = document.startViewTransition(() => this.theme = theme);
            const radius = Math.hypot(Math.max(info.x, innerWidth - info.x), Math.max(info.y, innerHeight - info.y));
            transition.ready.then(() => document.documentElement.animate({ clipPath: [`circle(0px at ${info.x}px ${info.y}px)`, `circle(${radius}px at ${info.x}px ${info.y}px)`] }, { duration: 600, easing: 'ease-out', pseudoElement: '::view-transition-new(root)' }));
            transition.finished.then(() => document.styleSheets[0].deleteRule(0));
        };
        return {
            expose: {
                toggle,
                get isDark() {
                    return isDark();
                },
            },
            props: {
                theme: (value) => {
                    if (value === 'light')
                        return this.removeAttribute('dark');
                    if (value === 'dark')
                        return this.setAttribute('dark', '');
                    const change = () => {
                        darker.matches ? this.setAttribute('dark', '') : this.removeAttribute('dark');
                        this.dispatchEvent(new Event('change'));
                    };
                    darker.onchange = change;
                    change();
                }
            }
        };
    }
}) {
}
Page.define(name);
