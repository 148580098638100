import { useElement } from './core/element.js';
import './ripple.js';
const name = 's-fab';
const props = {
    hidden: false
};
const style = `
:host{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  flex-shrink: 0;
  height: 56px;
  font-size: .875rem;
  border-radius: 16px;
  background: var(--s-color-primary-container, ${"#aeecff"});
  color: var(--s-color-on-primary-container, ${"#001f26"});
  transition: box-shadow .1s ease-out, transform .1s ease-out;
  font-weight: 500;
  white-space: nowrap;
  text-transform: capitalize;
  box-shadow: var(--s-elevation-level2, ${"0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)"});
  padding: 0 16px;
}
:host([hidden=true]){
  transform: scale(0);
  pointer-events: none;
}
::slotted(*){
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  fill: currentColor;
  color: currentColor;
}
::slotted(s-icon[slot=start]),
::slotted(svg[slot=start]){
  margin: 0 8px 0 0;
}
::slotted(s-icon[slot=end]),
::slotted(svg[slot=end]){
  margin: 0 0 0 8px;
}
:host([rippled]){
  box-shadow: var(--s-elevation-level3, ${"0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12)"});
}
@media (pointer: fine){
  :host(:hover){
    box-shadow: var(--s-elevation-level3, ${"0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12)"});
  }
}
`;
const template = `
<slot name="start"></slot>
<slot></slot>
<slot name="end"></slot>
<s-ripple attached="true" part="ripple"></s-ripple>
`;
export class FAB extends useElement({ style, template, props, syncProps: ['hidden'] }) {
}
FAB.define(name);
