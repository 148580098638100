import { useElement } from './core/element.js';
const name = 's-divider';
const props = {};
const style = `
:host{
  display: block;
  height: 1px;
  flex-shrink: 0;
  background: var(--s-color-outline-variant, ${"#bfc8cb"});
  margin: 0 16px;
}
`;
const template = `<slot></slot>`;
export class Divider extends useElement({ style, template, props }) {
}
Divider.define(name);
