import { useElement } from './core/element.js';
const name = 's-skeleton';
const props = {};
const style = `
:host{
  display: block;
  height: 16px;
  animation: skeleton 1.4s ease infinite;
  background: linear-gradient(90deg, var(--s-color-surface-container-high, ${"#f2f4f5"}) 25%, var(--s-color-surface-container-highest, ${"#ffffff"}) 37%, var(--s-color-surface-container-high, ${"#f2f4f5"}) 63%);
  background-size: 400% 100%;
  border-radius: 8px;
}
@keyframes skeleton{
  0%{
    background-position: 100% 50%;
  }
  100%{
    background-position: 0 50%;
  }
}
`;
const template = `
`;
export class Skeleton extends useElement({ style, template, props }) {
}
Skeleton.define(name);
